/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */
 
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * Copyright © SoftServe, Inc. All rights reserved.
 *
 * @license proprietary (Non-free Software License)
 */

:root {
    --header-logo-width: 140px;
    --header-logo-height: 66px;
    --header-height: 60px;
    --header-nav-height: 60px;
    --header-total-height: calc(
        var(--header-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top)
    );

    @include desktop {
        --header-top-menu-height: 32px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    width: 0;
    height: 0;
}

@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin visible-on-mobile {
    @include button-visible;

    @include desktop {
        @include button-invisible;
    }
}

@mixin logo-visible {
    opacity: 1;
    max-width: 100%;
}

.Header {
    align-items: center;
    background: var(--color-white);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    z-index: 100;
    box-shadow: 0 6px 8px 0 #00000014;
    inset-block-start: var(--demo-notice-height);

    > * {
        max-width: var(--content-wrapper-width);
        width: 100%;
    }


    &_menu,
    &_menu_subcategory {
        .Header-Button_type {
            &_close {
                @include desktop {
                    @include button-visible;
                }
            }

            &_menu {
                @include desktop {
                    @include button-invisible;
                }
            }
        }
    }

    &_name {
        &_menu {
            border-block-end: none;
        }

        &_search,
        &_menu_subcategory {
            border-block-end: 1px solid var(--primary-divider-color);
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &:not(&_type_contact):not(&_type_minicart) {
            margin-inline-end: 50px;
        }

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.1;
        }

        &_type {
            &_menu,
            &_minicart {
                @include button-visible;

                @include desktop {
                    height: 24px;
                }
            }

            &_account {
                @include button-visible;
            }

            &_home {
                @include visible-on-mobile;
            }

            &_minicart {
                @include desktop {
                    margin-inline-start: 50px;
                }
            }

            &_back {
                height: 36px;
                width: 36px;
                border-radius: 5px;
                background-color: #ffffff;
                box-shadow: 0 0 6px 0 #0000001f;
                cursor: pointer;

                @include mobile {
                    margin-inline-start: 10px;
                }
            }

            &_close {
                .CloseIcon {
                    width: 27px;
                    height: 27px;
                }
            }

            &_share {
                @include button-visible;
            }

            &_compare {
                @include button-visible;
            }

            &_products {
                @include button-visible;
            }

            &_about {
                @include button-visible;
            }

            &_expressDelivery {
                @include button-visible;
            }

            &_contact {
                @include button-visible;

                margin-inline-start: 50px;
            }
        }

        &[aria-hidden="true"] {
            display: none;
        }
    }

    &-ContactButtonWrapper {
        margin-inline-start: auto;
        margin-inline-start: 0px;
    }

    &-MinicartButtonWrapper {
        cursor: pointer;
        height: 100%;
    }

    &-CompareButtonWrapper {
        margin-inline-start: 35px;
        height: 24px;

        a {
            all: initial;
        }
    }

    #Cart,
    #Search,
    #User {
        @include desktop {
            fill: var(--primary-blue-color);
            stroke: var(--primary-blue-color);
            /* stylelint-disable-next-line number-max-precision */
            stroke-width: 0.2px;
        }
    }

    &-CompareCount {
        background: #0a0903;
        border-radius: 50%;
        color: var(--color-white);
        height: 17px;
        padding: 2px;
        position: absolute;
        width: 17px;
        display: flex;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-family: "Muli", sans-serif;

        @include mobile {
            inset-block-start: -7px;
            inset-inline-end: -11px;
        }

        @include desktop {
            inset-block-start: -10px;
            inset-inline-end: -12px;
        }
    }

    &-LogoWrapper {
        inset-block-end: 0;
        height: var(--header-logo-height);
        inset-inline-start: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        width: var(--header-logo-width);
        margin: auto;

        @include mobile {
            margin: auto;
        }

        @include desktop {
            inset-inline: unset 40px;

            @include logo-visible;
        }

        &_isVisible {
            @include logo-visible;
        }
    }

    &-MyAccountButtonWrapper {
        cursor: pointer;
        margin-inline-start: 50px;
    }

    &-SearchButtonWrapper {
        cursor: pointer;
        margin-inline-start: 50px;
    }

    &-Minicart {
        &ItemCount {
            border-radius: 50%;
            color: #ffffff;
            padding: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            text-align: center;
            font-family: "Roboto-Bold-Bold", sans-serif;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 9px;
            background: #F43636;
            width: 20px;
            height: 20px;
            font-size: 10px;

            @include mobile {
                inset-block-start: 16px;
                inset-inline-start: 50%;
            }

            @include desktop {
                inset-block-start: 10px;
                inset-inline-start: 50%;
            }
        }
    }

    &-Nav {
        align-items: center;
        display: flex;
        height: var(--header-nav-height);
        padding-inline: 14px;
        padding: 0;

        @include ultra-wide-desktop {
            padding: 0px 20px;
        }



        @include desktop {
            justify-content: unset;
            height: 80px;
        }

        @include mobile {
            height: 60px;
            padding: 0px;
        }
    }

    &-MyAccount {
        @include desktop {
            height: 24px;
        }
    }

    &-News {
        align-items: center;
        display: flex;

        &Button {
            font-size: 12px;
            margin-inline-start: 5px;
        }

        .ExclamationMarkIcon {
            inset-block-end: 1px;
            height: 16px;
            margin-inline-end: 10px;
            width: 16px;
        }
    }

    &-Title {
        color: var(--header-color);
        margin: auto;
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        inset-inline-end: 0;
        text-align: center;
        text-overflow: ellipsis;
        inset-block-start: -1px;
        inset-inline-start: 0;
        inset-block-end: 0;
        transition-duration: 200ms;
        transition-property: opacity;
        white-space: nowrap;
        width: 100%;
        line-height: 30px;
        height: 30px;
        /* stylelint-disable declaration-no-important */
        text-transform: none;


        @include mobile {
            color: var(--primary-blue-color);
            font-family: "Roboto-Bold", sans-serif;
            font-size: 16px;
            letter-spacing: 0;
            text-align: center;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 30px;
        }

        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 140px);
            }
        }
    }

    &-TopMenu,
    &-Nav {
        display: flex;
        margin: auto;
        max-width: var(--content-wrapper-width);
    }

    &-TopMenu {
        justify-content: space-between;
        height: var(--header-top-menu-height);
        padding-inline: 32px;
        padding-block-start: 16px;
    }

    &-Switcher {
        align-items: center;
        display: flex;
    }

    &_isCheckout {
        .Header {
            &-Button_type_account {
                display: block;
            }

            &-Button_type_close,
            &-Button_type_back {
                @include desktop {
                    display: none;
                }
            }

            &-MyAccount {
                @include desktop {
                    margin-inline-start: auto;
                    height: 24px;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 10px;
                    width: auto;
                }
            }
        }

        .MyAccountOverlay {
            @include mobile {
                inset-block-start: var(--header-total-height);
                height: calc(100vh - var(--header-total-height));
                position: fixed;
                width: 100vw;
            }
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: 80px;

        @include mobile {
            margin-block-end: 70px;
        }

        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    &_name_popup {
        z-index: 400;
    }

    &-SearchFieldWrapper {
        position: absolute;
        max-width: 600px;
        width: 100%;
        padding-inline-end: 38px;
        /* stylelint-disable-next-line csstools/use-logical */
        bottom: -20px;
        right: -5px;

        @media (min-width: 375px) and (max-width: 1440px) {
            padding-inline-end: 54px;
        }

        input:focus {
            border: 1px solid var(--input-border-color);
        }

        & > div {
            width: 100%;
        }
    }
}

// Set styliing for links in the Header

div[class^="Header-"][class$="Wrapper"] {
    font-family: "Roboto-Bold", sans-serif;
    text-transform: capitalize;

    a {
        color: var(--primary-blue-color);
        font-size: 16px;
        line-height: 19px;
    }
}

.active_menu {
    a {
        color: #9099B4;
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        font-family: "Roboto-Bold", sans-serif;
    }
}

.active_menu svg {
    color: #9099B4;
}

.NotificationList {
    position: fixed;
    inset-inline-end: 0px;

    @include  desktop {
        inset-inline-start: 50%;
        inset-block-start: 12%;
        transform: translate(-50%, -50%)
    }
}

.Notification-Text {
    margin-inline-end: 0px;
    padding: 0;
}

.Notification {
    margin: auto;
    box-shadow: 0px 7px 16px rgba(47, 102, 60, 0.25);
}

.homepage-video {
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.SlickSliderBlock .slick-slider {
    margin: 0 -10px;

    .slick-list {
        margin: 0 0px;
    }

    .ProductCard {
        margin-block-end: 20px;
    }

    @include  mobile {
        margin: 0px 6px;

        .ProductCard:last-child {
            margin-block-end: 0px;
        }
    }
}

@include  desktop {
    .about-us-home-custom {
        display: none;
    }
}

.about-us-home-custom {
    height: 480px;
    width: auto;
    overflow: hidden;
    padding-block-start: 20px;
    margin: 0px 14px 90px;
    cursor: pointer;

    img {
        object-position: center;
        //object-fit: cover;
        border-radius: 24px;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 0;
        inset-block-start: 0;
        inset-inline-start: 0;
        cursor: pointer;
    }

    .bc-image {
        position: absolute;
        z-index: 1;
        cursor: pointer;
    }
}

.HomePage {
    @include mobile {
        .CmsPage {
            margin-block-end: 20px !important;
        }
    }
}
